import React from 'react';
import {JSONWebtokenGetFacilitatorToken} from '../../../AxiosActions.js'
import {FormattedMessage} from 'react-intl';
import {Redirect} from 'react-router-dom';

function getUrlParams(search) {
    let hashes = search.slice(search.indexOf('?') + 1).split('&');
    let params = {};
    hashes.map(hash => {
        let [key, val] = hash.split('=');
        params[key] = decodeURIComponent(val);
    });

    return params;
}

export default class CheckSSO extends React.Component {
    constructor(props) {
        super(props);
        this.JSONWebTokenLogin = this.JSONWebTokenLogin.bind(this);
        let params = getUrlParams(this.props.location.search);
        let token = params['token'];
        let game_id = params['game_id'];
        this.state = {
            directToLogin: false,
            directToGame: false,
            activeGameId : null,
            displayErrorMessage: true ? !token : false
        };
        if (token) {
            this.JSONWebTokenLogin(token);
        }
    }

    JSONWebTokenLogin(token){
        let sent_data = {'token': token};
        JSONWebtokenGetFacilitatorToken(sent_data).then(res => {
            let token = res.data.token;
            let game_id = res.data.game_id;

            if (token !== null) {
                let d = new Date();
                d.setTime(d.getTime() + (3*60*60*1000));
                document.cookie = 'facilitator_TOKEN' + '=' + token + '; expires=' + d.toGMTString() + ';';
                if (game_id !== null) {
                    this.setState({
                        directToGame: true,
                        activeGameId: game_id
                    });
                }
                else {
                    this.setState({directToLogin: true});
                }
            }
        }).catch(error => {
            //wrong credentials
            if (error.response.status === 400) {
                 this.setState({displayErrorMessage: true});
            }
        });
    }
    render(){
        let content = null;
        if (this.state.displayErrorMessage) {
            content = (
                <div className="app-container">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="title-container col-md" style={{paddingTop:'2rem', marginBottom:0,}}>
                                <h1 className="app-title">
                                    <FormattedMessage id='errorMessage'/>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (this.state.directToGame) {
              return <Redirect
                push to={
                    {pathname: '/game', state:{
                        user_type: 'facilitator',
                        instance_id: this.state.activeGameId
                    }}}
            />;
        }
        else if (this.state.directToLogin) {
            content = (<Redirect push to={{pathname: 'login'}}/>);
        }
        
        else {
            content = (
                <div className="app-container">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="title-container col-md" style={{paddingTop:'2rem', marginBottom:0,}}>
                                <h1 className="app-title">
                                    <FormattedMessage id='waitMoment'/>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return content;
    }
}