import React, {Component} from 'react';
import arrowLeft from "static/svg/arrow-left.svg";
import plus from "static/svg/plus.svg";
import star from "static/svg/star.svg";
import greyStar from "static/svg/grey-star.svg";
import {Redirect} from 'react-router-dom';
import Slider from 'rc-slider';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FormattedMessage } from 'react-intl';
import { createNewGame, GetFacilitatorGames } from '../../../AxiosActions.js';

//Styles
import 'rc-slider/assets/index.css';

function getCookie(name) {
    let value = "; " + document.cookie;
	let parts = value.split("; " + name + "=");
	if (parts.length == 2)
        return parts.pop().split(";").shift();
}

export default class GameSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sent: false,
            gameId: "",
            step: 0,
            groups:3,
            game: null,
            redirect:false,
            instance_id:null,
        };

        this.nextStep = this.nextStep.bind(this);
        this.previousStep = this.previousStep.bind(this);
        this.setGroupAmount = this.setGroupAmount.bind(this);
        this.selectGame = this.selectGame.bind(this);
        this.createGame = this.createGame.bind(this);
    }

    createGame(){
        let token = getCookie("facilitator_TOKEN");
        if (token === null)
            this.props.logOut();

        let data = {
            game_id: this.state.gameId,
            groups: this.state.groups,
        };

        createNewGame(data, token).then(res => {
            let id = res.data.instance_id;
            if (id !== null){
                this.setState({
                    redirect: true,
                    instance_id: id
                })
            }
            else {
            }
      }).catch(error => {
          if (error.response.status === 401)
                this.props.logOut();
        });
    }

    nextStep(){
        this.setState({step:this.state.step + 1})
    }

    previousStep(){
        this.setState({step:this.state.step - 1})
    }

    selectGame(gameId){
        this.setState({gameId: gameId});
        this.nextStep();
    }

    setGroupAmount(value){
        this.setState({groups: value})
    }
    
    render(){
         if (this.state.redirect) {
            return <Redirect
                push to={
                    {pathname: '/game', state:{
                        user_type: 'facilitator',
                        instance_id: this.state.instance_id
                    }}}
            />;
        }
        let step = [
            <GameList
                selectGame={this.selectGame}
                language={this.props.language}
                logOut={this.props.logOut}
            />,
            <GroupSize
                setAmountGroups={this.setGroupAmount}
                createGame={this.createGame}
                previousStep={this.previousStep}
                logOut={this.props.logOut}
            />
        ];
        let view;
        view = step[this.state.step];


        return (
            <span>
                {view}
            </span>
        );
    }
}

class GameList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            sent: false,
            gameId: "",
            games: {},
        };

        this.openAccordion = this.openAccordion.bind(this);
        this.renderStars = this.renderStars.bind(this);
    }

    componentDidMount() {
        let token = getCookie("facilitator_TOKEN");
        if (token == null)
            this.props.logOut();

        GetFacilitatorGames(this.props.language, token)
            .then(res => {
                let games = res.data.games;
                if (games !== null){
                    this.setState({
                        games: games
                    })
                }
        }).catch(error => {
            if (error.response.status === 401)
                this.props.logOut();
        });
    }

    openAccordion(index){
        let element = document.getElementById(`icon-${index}`);
        element.style.transform == 'rotate(45deg)' ? element.style.transform = '' : element.style.transform='rotate(45deg)';
    }

    renderStars(rating){

        if (rating == 0){
             return (
                 <span className="rating-stars">
                     <img src={greyStar}/>
                     <img src={greyStar}/>
                     <img src={greyStar}/>
                 </span>
             );
        }
        else if(rating == 1){
            return (
                <span className="rating-stars">
                    <img src={star}/>
                    <img src={greyStar}/>
                    <img src={greyStar}/>
                </span>
            );
        }
        else if(rating == 2){
            return (
                <span className="rating-stars">
                    <img src={star}/>
                    <img src={star}/>
                    <img src={greyStar}/>
                </span>
            );
        }
        else if(rating== 3){
            return (
                <span className="rating-stars">
                    <img src={star}/>
                    <img src={star}/>
                    <img src={star}/>
                </span>
            );
        }
    }
    render() {
        let games = this.state.games;
        games = this.state.games;

        let categoryList = Object.keys(games).map((category, index) => {
            if (Object.keys(games[category]).length > 0) {
                let gameList = Object.keys(games[category]).map((game, idx) => {
                let gameObject = games[category][game];
                let keyNumber = `${index}-${idx}`;

                return (
                    <div className="row" key={keyNumber}>
                        <div className="d-flex justify-content-center col-md">
                            <Accordion
                                allowZeroExpanded
                                key={`accordion-${keyNumber}`}
                                className="accordion-container"
                                onChange={() => this.openAccordion(keyNumber)}
                            >
                                <AccordionItem className="accordion-item">
                                    <AccordionItemHeading
                                        className="accordion-title-container"
                                    >
                                        <AccordionItemButton>
                                       <h2 className="accordion-title"
                                            style={{
                                                width: 'auto',
                                                marginBottom: '0'
                                            }}> {game} <span
                                            id={`icon-${keyNumber}`} style={{
                                            lineHeight: '6rem',
                                            float: 'right',
                                            marginRight: '2rem'
                                        }} className="accordion-icon"><img
                                            style={{
                                                height: '3rem',
                                                width: '3rem',
                                                marginBottom: '1rem'
                                            }}
                                            src={plus}/></span>
                                        </h2>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel
                                        className="accordion-body-container">
                                        {/*
                                            <div className="row"
                                                 style={{paddingBottom: '1rem'}}>
                                                <div className="col-md">
                                                <span
                                                    style={{fontSize: '2rem'}}>Lukioissa:{this.renderStars(gameObject.rating_1)}</span>
                                                    <span style={{
                                                        float: 'right',
                                                        fontSize: '2rem'
                                                    }}>Ammattikouluissa: {this.renderStars(gameObject.rating_2)}</span>
                                                </div>
                                            </div>
                                        */}
                                        <div className="row">
                                            <div
                                                className="d-flex justify-content-center col-md">
                                                {gameObject.content}
                                            </div>
                                        </div>
                                        <div className="row"
                                             style={{paddingTop: '3%'}}>
                                            <div
                                                className="d-flex justify-content-center col-md">
                                                <button
                                                    className="begin-button"
                                                    onClick={(game) => this.props.selectGame(gameObject.game_id)}
                                                >
                                                    <FormattedMessage
                                                        id='startbutton'/>
                                                </button>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                );
            });
                return (
                    <div className="container" style={{padding:0,}}>
                        <div className="row">
                            <div className="d-flex justify-content-center col-md">
                                <p className="app-paragraph" style={{paddingBottom:'0.5em'}}>{category}</p>
                            </div>
                        </div>
                        {gameList}
                    </div>);
                }
            });

        return (
            <div className="container-fluid" style={{overflow: 'auto'}}>
                <div className="row">
                    <div className="col-md">
                        <h1 className="app-title" style={{paddingLeft:'27%', paddingRight:'27%', paddingTop:'1em', paddingBottom:'0.5em'}}><FormattedMessage id='facilitator.gameselect.title'/></h1>
                    </div>
                </div>
                {categoryList}
            </div>
        );
    }
}

class GroupSize extends Component{
    constructor(props) {
        super(props);
        this.state = {
            groups:3,
            screenWidth: null,
        };

        this.handleSlider = this.handleSlider.bind(this);
        this.handleGroup = this.handleGroup.bind(this);
    }

    componentDidMount(){
        this.setState({screenWidth: window.innerWidth})
    }

    handleSlider(value){
        this.setState({groups: value })
        this.props.setAmountGroups(value);
    }

    handleGroup(e){
        let value = e.target.value;
        try{
            value = parseInt(value)
        }catch(e){
            this.setState({groups: "" })
            this.props.setAmountGroups(3);
            return;
        }
        if(isNaN(value)){
            this.setState({groups: "" })
            this.props.setAmountGroups(3);
        }
        else if( value > 6){
            this.setState({groups: 6 })
            this.props.setAmountGroups(6);
        }
        else if( value < 3){
            this.setState({groups: 3 })
            this.props.setAmountGroups(1);
        }
        else{
            this.setState({groups: value })
            this.props.setAmountGroups(value);
        }
    }

    render() {
        let handleStyle;
        if(this.state.screenWidth > 1000){
            handleStyle={width:'5rem', height:'5rem', top:'-1rem', border:'0'}
        } else {
            handleStyle={width:'5rem', height:'5rem', top:'-1.5rem', border:'0'}
        }
        return (
            <div className="container-fluid facilitator-container  scrollable-container">
                <span className="back-button" onClick={() => this.props.previousStep()}><img style={{width:"100%", height:"100%"}} src={arrowLeft}/></span>
                <div className="row">
                    <div className="col-md">
                        <h1 className="app-title" style={{paddingLeft:'27%', paddingRight:'27%', paddingTop:'1em', paddingBottom:'1em'}}><FormattedMessage id='facilitator.groupsize.title'/></h1>
                    </div>
                </div>
                <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <input className="game-select"
                                maxLength="1"
                                style={{width:'3em'}} 
                                onChange={this.handleGroup} 
                                value={this.state.groups} 
                                step={1}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row" style={{paddingTop:'8em', paddingBottom:'5em'}}>
                        <div className="d-flex justify-content-center col-md">
                            <Slider
                            style={{width:'80%', position:'relative'}}
                            handleStyle={handleStyle} 
                            trackStyle={{height:'0.6em', backgroundColor:'white'}} 
                            railStyle={{height:'0.6em', opacity:'0.5'}} 
                            min={3} 
                            max={6} 
                            defaultValue={3}
                            onChange={this.handleSlider}
                            value={this.state.groups > 0 ? this.state.groups : 3}/>
                        </div>
                    </div>
                    <div className="row" style={{paddingTop:'2em'}}>
                        <div className="d-flex justify-content-center col-md">
                        <p className="app-paragraph-small" > <FormattedMessage id='facilitator.groupsize.instruction'/> </p>
                        </div>
                    </div>
                    <div className="row" style={{paddingTop:'18em'}}>
                        <div className="col-md">
                            <button className="orange-button" onClick={this.props.createGame}><FormattedMessage id='nextbutton'/></button>
                        </div>
                    </div>
                </div>
        );
        
    }
}