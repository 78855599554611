import React from 'react';
import {FormattedMessage} from 'react-intl'
import Star from 'static/svg/star.svg'
import GreyStar from 'static/svg/grey-star.svg'
import LeaveGame from 'static/svg/leave-game.svg'


export default class RateScreen extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            stars: 1,
        };

        this.setStars = this.setStars.bind(this);
        this.sendRating = this.sendRating.bind(this);
    }

    sendRating(){
        this.props.sendSocketMessage({
            command: 'gameEnd.sendRating',
            data: {
                rating: this.state.stars,
            }
        });
    }

    setStars(stars){
        this.setState({stars})
    }

    render() {
        return (
            <div className="app-container">
            <div className="container-fluid scrollable-container.player-container">
                <span className="exit-button" onClick={() => this.props.exitGame(this.props.module) }><img style={{width:"100%", height:"100%"}} src={LeaveGame}/></span>

                <div className="row" style={{marginTop:'5rem'}}>
                        <div className="title-container col-md" style={{paddingTop:0, marginBottom:0,}}>
                            <h1 className="app-title"><FormattedMessage id='player.ratescreen.title' /></h1>
                        </div>
                </div>
                <div className="row" style={{marginTop:'5rem'}}>
                        <div className="d-flex justify-content-center col-md" style={{paddingTop:0, marginBottom:0,}}>
                            <p className="app-paragraph" style={{fontSize:'5rem', width:'80%',}}><FormattedMessage id='player.ratescreen.instruction' /></p>
                        </div>
                </div>
                <div className="row" style={{marginTop:'5rem'}}>
                    <div className="d-flex justify-content-center col-md">
                        <img className="big-star" onClick={() => this.setStars(1)} src={this.state.stars >= 1 ? Star : GreyStar}/>
                        <img className="big-star" onClick={() => this.setStars(2)} src={this.state.stars >= 2 ? Star : GreyStar}/>
                        <img className="big-star" onClick={() => this.setStars(3)} src={this.state.stars >= 3 ? Star : GreyStar}/>
                    </div>
                </div>                                        
                <div className="row" style={{paddingTop:'3%'}}>
                    <div className="d-flex justify-content-center col-md">
                            <button 
                                className="orange-button" 
                                style={{marginTop:'50%'}} 
                                onClick={this.sendRating}
                            >
                                <FormattedMessage id='send' />
                            </button>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}