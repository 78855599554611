import axios from 'axios';
import config from './config.js'

const Axios = axios.create({
    baseURL: config.BASEURL,
});

    export function playerJoinGame(game_id){
           return(Axios.get(`api/get-player-game-instance/?gameCode=${game_id}`))
    }

    export function screenJoinGame(game_id){
        return (Axios.get(`api/get-screen-game-instance/?screenCode=${game_id}`))
    }

    export function GetFacilitatorGames(language_code, token){
         return (
             Axios.get(
                 `api/get-facilitator-games/?language_code=${language_code}`,
                 { headers: {"Authorization" : `Token ${token}`}}
             )
         )
    }
    export function getLoginStatus(token){
        return (
            Axios.get(
                `api/get-login-status/`,
                {headers: {"Authorization" : `Token ${token}`}}
            )
        )
    }

    export function createNewGame(data, token){
        return (Axios({
            method: 'POST',
            url: `api/create-new-game/`,
            headers: {"Authorization" : `Token ${token}`},
            data: data
        }));
    }

    export function getFacilitatorToken(data){
        return (Axios.post(`api/get-auth-token/`, data))
    }

    export function JSONWebtokenGetFacilitatorToken(data) {
        return (Axios.post(`api/JSONwebtoken-get-auth-token/`, data))
    }

