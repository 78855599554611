import React from 'react';
import {Switch, Route } from 'react-router';

import ScreenGameSelection from './game/views/screenviews/GameSelection.jsx';
import CheckLogin from './game/views/facilitatorviews/CheckLogin.jsx';
import CheckSSO from './game/views/facilitatorviews/CheckSSO.jsx';
import HintScreen from './game/views/playerviews/multiquestion/AnswerScreen.jsx';
import GameSelection from './game/views/playerviews/GameSelection.jsx';
import GameApp from './game/GameApp.jsx';
import ScreenGameApp from './game/ScreenGameApp.jsx';

export default (
  <Switch>
    <Route
        key="index"
        name="index"
        exact path="/"
        component={GameSelection}
    >
    </Route>
    <Route
        key="game"
        name="game"
        path="/game"
        component={GameApp}
    >
    </Route>
    <Route
        key="screen-game"
        name="screen-game"
        path="/screen-game"
        component={ScreenGameApp}
    >
    </Route>
    <Route
        key="screen"
        name="screen"
        path="/screen"
        component={ScreenGameSelection}
    >
    </Route>
    <Route
        key="login"
        name="login"
        path="/login"
        component={CheckLogin}
    >
    </Route>
    <Route
        key="opintokamu-sso"
        name="opintokamu-sso"
        path="/opintokamu-sso"
        component={CheckSSO}>
    </Route>
    <Route
        key="hint"
        name="hint"
        path="/hint"
        component={HintScreen}
    >
    </Route>
  </Switch>
);
