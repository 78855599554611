import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {screenJoinGame} from '../../../AxiosActions.js'
import {FormattedMessage} from 'react-intl';

export default class GameSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sent: false,
            game_id: "",
            redirect: false,
            instance_id: ""
        };
        this.joinGame = this.joinGame.bind(this);
        this.updateState = this.updateState.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }

    handleInput(e) {
        if (e.key === 'Enter' && this.state.game_id.length == 4) {
            e.target.blur();
            this.joinGame();
        }
    }

    joinGame(){
        screenJoinGame(this.state.game_id)
            .then(res => {
                let id = res.data.instance_id;
                if (id !== null){
                    this.setState({
                        redirect: true,
                        instance_id: id
                    })
                }
                else {
                    this.setState({
                        game_id: ""
                    });
                }
          });
    }
    updateState(e) {
        let value  = e.target.value.toUpperCase();
        this.setState({game_id: value});
    }
    render() {
        if (this.state.redirect) {
            return <Redirect
                push to={
                {
                    pathname: '/screen-game',
                    state: {
                        user_type: "screen",
                        instance_id: this.state.instance_id
                    }
                }}
            />;
        }
        else {
            return (
                <div className="screen-container">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="d-flex justify-content-center col-md">
                                <h1 className="screen-title" style={{paddingTop:'4rem', paddingBottom:'2rem'}}><FormattedMessage id="screen.welcomescreen.title" /></h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md">
                                <p className="screen-paragraph"><FormattedMessage id='app.gameselection.gameid'/></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="d-flex justify-content-center col-md">
                                <input className="game-select-screen" type="text" maxLength="4" style={{marginTop:'1em', width:'7em', }} onKeyPress={(e) => this.handleInput(e)} value={this.state.game_id} onChange = {this.updateState} placeholder = "XXXX" />
                            </div>
                        </div>
                        <div className="row" style={{paddingTop: '20rem'}}>
                            <div className="col-md">
                                <div onClick={e => {this.state.game_id.length != 4 ? e.preventDefault() : null }} disabled={true}>
                                    <button className="orange-button-screen" onClick={this.joinGame} style={this.state.game_id.length != 4 ? { color: '#a1a1a1', cursor: 'not-allowed'} : null}><FormattedMessage id="nextbutton" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

