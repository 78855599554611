import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import ScreenStepper from '../../../../utils/ScreenStepper.jsx'

export default class QuestionScreen extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        let data = this.props.information.data;
        let groups = data.groups;
        let scoreRow;

        scoreRow = Object.keys(groups).map((index) => {
            let group = groups[index];
            let group_text = "";
            let name = group.name;
            let score = group.score ? group.score : 0;

            if(group.name === null || group.name == ""){
                group_text = <FormattedMessage id = "group"/>
                name = index
            }
            if(name.length > 13){
                name = name.slice(0,11) + '...';
            }
            return(
                <p className="screen-group-small"> {group_text} {name} <span className="score"> {score} </span> </p>
            )
        });
        return (
            <div className="screen-container">
                <div className="container-fluid d-flex flex-column">
                    <p className="screen-gameid-small"> <FormattedMessage id = "app.gameselection.gameid"/>: {this.props.information.data.player_code}</p>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            {scoreRow}
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center" style={{flex:'1'}}>
                        <div className="row" >
                            <div className="d-flex justify-content-center col-md">
                                <p className="screen-description" style={{width:'70%'}} >{data.question}</p>
                            </div>
                        </div>
                    </div>
                    <ScreenStepper
                        data={data}
                    />

                </div>
            </div>
        );
    }
}