import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import ScreenStepper from '../../../../utils/ScreenStepper.jsx'

export default class AnswerScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            interval: {},
            timeout: null
        };

        //this bindings
        this.checkScores = this.checkScores.bind(this);
        this.animateScore = this.animateScore.bind(this);
    }
    componentDidMount() {
        this.state.timeout = setTimeout(() => this.checkScores(), 3000);
    }
    componentDidUpdate() {
        if(typeof(this.state.timeout) == 'undefined') {
            this.state.timeout = setTimeout(() => this.checkScores(), 3000);
        }
    }
    componentWillUnmount() {
        clearTimeout(this.state.timeout);
        for(var key in this.state.interval){
            clearInterval(this.state.interval[key]);
        }
    }
    checkScores() {
        let data = this.props.information.data;
        let groups = data.groups;
        for(var index in groups){
            let idx = index;
            if(groups[index].score > groups[index].previous_score && typeof(this.state.interval[index]) == 'undefined'){
                this.state.interval[index] = setInterval(() => this.animateScore(idx), 10);
            }
        }
    }
    animateScore(index) {
        let element = document.getElementById(`group-${index}-score`);
        let element_add = document.getElementById(`group-${index}-add`);

        let data = this.props.information.data;
        let groups = data.groups;

        if(element.innerHTML == groups[index].score){
            clearInterval(this.state.interval[index]);
            element_add.classList.add('fade-out');

        } else {
            let value = parseInt(element.innerHTML);
            value++;
            element.innerHTML = value;
        }
    }

    render(){
        let data = this.props.information.data;
        let groups = data.groups;
        let scoreRow;

        scoreRow = Object.keys(groups).map((index) => {
            let group = groups[index];
            let group_text = "";
            let name = group.name;
            let score_add = group.score - group.previous_score;

            if(group.name === null || group.name == ""){
                group_text = <FormattedMessage id = "group"/>
                name = index
            }

            if(name.length > 13){
                name = name.slice(0,11)+'...'
            }
            let score_add_element = "";
            if(score_add > 0){
                score_add_element = <span id={`group-${index}-add`} className="score-add"> +{score_add} </span>
            }
            return(
                <div style={{height:'7rem'}}>
                    <p className="screen-group-small"> {group_text} {name}
                        <span id={`group-${index}-score`} className="score">{group.previous_score != group.score ? group.previous_score : group.score}</span>
                    </p>
                    {score_add_element}
                </div>
            )
        });
        return (
            <div className="screen-container">
                <div className="container-fluid d-flex flex-column">
                    <p className="screen-gameid-small"> <FormattedMessage id = "app.gameselection.gameid"/>: {this.props.information.data.player_code} </p>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            {scoreRow}
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center" style={{flex:'1'}}>
                        <div className="row" >
                            <div className="d-flex justify-content-center col-md">
                                <h2 className="screen-title-small" style={{width:'70%'}} ><FormattedMessage id='screen.factquestion.answerScreen.title'/></h2>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="d-flex justify-content-center col-md">
                                <p className="screen-description" style={{width:'70%'}} onClick={this.checkScores} >{data.right_answer}</p>
                            </div>
                        </div>
                    </div>
                    <ScreenStepper
                        data={data}
                    />

                </div>
            </div>
        );
    }
}