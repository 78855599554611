import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {playerJoinGame} from '../../../AxiosActions.js'

export default class GameSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sent: false,
            game_id: "",
            redirect: false,
            instance_id: ""
        };

        this.joinGame = this.joinGame.bind(this);
        this.updateState = this.updateState.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }

    handleInput(e) {
        if (e.key === 'Enter' && this.state.game_id.length == 4) {
            e.target.blur();
            this.joinGame();
        }
    }

    joinGame(){
        playerJoinGame(this.state.game_id).then(res => {
                let id = res.data.instance_id;
                if (id !== null){
                    this.setState({
                        redirect: true,
                        instance_id: id
                    })
                }
                else {
                    this.setState({
                        game_id: ""
                    });
                }
          });
    }
    updateState(e) {
        let value  = e.target.value.toUpperCase();
        this.setState({game_id: value});
    }
    render(){
        if (this.state.redirect) {
            return <Redirect
                push to={
                    {pathname: '/game', state:{
                        user_type: "player",
                        instance_id: this.state.instance_id
                    }}}
            />;
        }
        else {
            return (
                <div className="app-container">
                    <div className="container-fluid scrollable-container" >

                        <div className="row">
                            <div className="col-md">
                                <h1 className="app-title" style={{ paddingTop:'1em', paddingBottom:'1em', textAlign: 'center' }}><FormattedMessage id='app.gameselection.title'/></h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md">
                                <p className="app-paragraph" ><FormattedMessage id='app.gameselection.gameid'/></p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="d-flex justify-content-center col-md">
                                <input className="game-select" type="text" maxLength="4" onChange = {this.updateState}  onKeyPress={(e) => this.handleInput(e)} value={this.state.game_id}   placeholder = "XXXX" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="d-flex justify-content-center col-md">
                                <p className="app-paragraph" style={{width:'70%', fontSize:'5em', marginTop:'1em'}}><FormattedMessage id='app.gameselection.instruction'/></p>
                            </div>
                        </div>

                        <div className="row" style={{paddingTop: '5em'}}>
                            <div className="col-md">
                                <div onClick={e => {this.state.game_id.length != 4 ? e.preventDefault() : null }} disabled={true}>
                                    <button className="orange-button" onClick={this.joinGame} style={this.state.game_id.length != 4 ? { color: '#a1a1a1', cursor: 'not-allowed'} : null}><FormattedMessage id='nextbutton'/></button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            );
        }
    }
}