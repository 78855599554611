import React, {Component} from 'react';
import leaveGame from "static/svg/leave-game.svg";
import plus from "static/svg/plus.svg";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
} from 'react-accessible-accordion';
import { FormattedMessage } from 'react-intl';


export default class GroupModeration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disable_next: false,
        };
        this.resetGroupName = this.resetGroupName.bind(this);
        this.nextState = this.nextState.bind(this);
    }

    nextState() {
        this.props.sendSocketMessage({
           command: 'game.next'
       });

       this.setState({disable_next: true});
       setTimeout(()=> this.setState({disable_next:false}), 5000);
   }

    resetGroupName(group) {
         this.props.sendSocketMessage({
            command: 'groupBuilding.resetGroupName',
            data: {
                groupIndex: group
            }
        });
    }

    render(){
        let {groups} = this.props.information.data;
        let groups_ready = 0;
        let amount_groups = Object.keys(groups).length;
        let groupList;

        groupList = Object.keys(groups).map((index) => {
            let group = groups[index];
            let group_size = Object.keys(group.players).length;
            let group_text = "";
            let name = group.name;
            let avatars = 0;

            for(var player in group.players){
                if(group.players[player].avatar) avatars++;
            }

            if (group_size !== 0 && avatars >= group_size)
                groups_ready ++;

            if(group.name === null || group.name === ""){
                group_text = <FormattedMessage id = "group"/> ;
                name = index
            }
            return (
                <div className="row" style={{marginLeft:'7.5%', marginRight:'7.5%'}}>
                    <div className="d-flex justify-content-center col-md">
                        <Accordion 
                        className="accordion-container"
                        >
                            <AccordionItem className="accordion-item">
                                <AccordionItemHeading
                                className="accordion-title-container"
                                style={{display:'table'}}
                                >
                                    <AccordionItemButton>
                                    <h2 className="accordion-title" style={{display: 'inline-block',}}>
                                        {group_text} {name} ({group_size})
                                    </h2>
                                    <span
                                        onClick={() => this.resetGroupName(index)}
                                        style={{display: 'inline-block', lineHeight:'6rem', float:'right', marginRight:'2rem', fontSize:'2rem', fontFamily:'Source Sans Pro'}} 
                                        className="accordion-icon">
                                        <FormattedMessage id='facilitator.groupmoderation.namereset' />
                                        <img
                                            style={{marginLeft:'1rem', height:'3.5rem', width:'3.5rem', transform:'rotate(45deg)'}}  
                                            src={plus}
                                        />
                                    </span>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            );
        });

        return (
            <div className ="app-container">
                <div className="container-fluid facilitator-container  scrollable-container">
                    <span className="exit-button" onClick={() => this.props.exitGame() }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>      
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <h1 className="app-title" style={{paddingTop:'1em'}}><FormattedMessage id='facilitator.groupmoderation.title'/></h1>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center col-md">
                        <p className="app-paragraph" style={{width: "70%", fontSize: "3rem", marginBottom: "3rem"}}>
                            <FormattedMessage id='facilitator.groupmoderation.info'/>
                        </p>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <h1 className="app-title" >{groups_ready}/{amount_groups}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <p className="app-paragraph" style={{width:'70%', fontSize:'4rem', marginBottom:'3rem'}}><FormattedMessage id='facilitator.groupmoderation.instruction'/></p>
                        </div>
                    </div>
                    {groupList}
                    <div className="row" style={{paddingTop:'3%'}}>
                        <div className="d-flex justify-content-center col-md">
                                <button 
                                    className="orange-button" 
                                    style={{}}
                                    onClick={this.state.disable_next ? null : () => this.nextState()}
                                >
                                    <FormattedMessage id='startgamebutton' />
                                </button>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}