import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import ScreenStepper from '../../../../utils/ScreenStepper.jsx'

export default class DebateWheel extends Component {
    constructor(props) {
        super(props);
        this.state={
            translateZ: -0,
            rotateY: 0,
        };
        this.interval;
        this.spinCarousel = this.spinCarousel.bind(this);
    }

    componentDidMount(){
        setTimeout(this.spinCarousel, 1000);
    }
    spinCarousel(){
        let {data} = this.props.information;
        let element = document.getElementById('carousel');
        let figures = document.getElementsByClassName('figure-container');
        let background = document.getElementsByClassName('background-element');
        let angle;

        switch(data.debate_group){
            case(1):
                angle = 60;
                break;
            case(2):
                angle = 180;
                break;
            case(3):
                angle = 300;
                break;
            case(4):
                angle = 120;
                break;
            case(5):
                angle = 240;
                break;
            case(6):
                angle = 360;
                break;

            default:
                angle = 0;
                break;
        }

        this.state.rotateY += angle + 5700;
        element.style.transform= `rotateY( ${-this.state.rotateY}deg )`
        background[0].style.transform = `rotateY( ${this.state.rotateY}deg )`

        for(var figure in figures){
            if(figures[figure].style){
                figures[figure].style.transform = `rotateY( ${this.state.rotateY}deg )`
            }
        }
    }
    render(){
        let {data} = this.props.information
        let {groups} = this.props.information.data;
        let scoreRow;
        let groupFigures;

        scoreRow = Object.keys(groups).map((index) => {
            let group = groups[index];
            let group_text = "";
            let name = group.name;

            if(group.name === null || group.name == ""){
                group_text = <FormattedMessage id = "group"/>
                name = index
            }

            if(name.length > 13){
                name = name.slice(0,11)+'...'
            }

            return(
                <p className="screen-group-small"> {group_text} {name} <span className="score"> {group.score} </span> </p>
            )
        });

        groupFigures = Object.keys(groups).map((index) => {
            let group = groups[index];
            let group_text = "";
            let name = group.name;
            let avatars;

            if(group.players){
                avatars = Object.keys(group.players).map((player_id) => {
                    let player = group.players[player_id]

                    if(player.avatar){
                        return(
                            <img src={player.avatar} style={{height:'7rem', width:'7rem',}}/>
                        )
                    }
                })
            }

            if(group.name === null || group.name == ""){
                group_text = <FormattedMessage id = "group"/>
                name = index
            }

            if(name.length > 13){
                name = name.slice(0,11)+'...'
            }

            return (
                <figure className="carousel-figure">
                    <div className="figure-container">
                        <div className="screen-big-team" style={{position:'relative', verticalAlign:'text-bottom', height:'100%', marginTop:0}}>
                            <div style={{width:'21rem', margin:'0 auto', maxHeight:'80%'}}>
                                {avatars}
                            </div>
                            <p className="screen-group-big" style={{position:'absolute', width:'100%', fontSize:'5rem', bottom:'0'}}>{group_text} {name}</p>
                        </div>
                    </div>
                </figure>)
        });

        return (
            <div className="screen-container">
                <div className="container-fluid d-flex flex-column" style={{overflowX: 'hidden'}}>
                    <p className="screen-gameid-small"> <FormattedMessage id = "app.gameselection.gameid"/>: {this.props.information.data.player_code} </p>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            {scoreRow}
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center" style={{flex:'1'}}>
                        <div className="row" >
                            <div className="d-flex justify-content-center col-md">
                                <p className="screen-description" style={{width:'70%'}}><FormattedMessage id='screen.debatewheel.title'/></p>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="d-flex justify-content-center col-md">

                            <section className="carousel-container">
                                <div id="carousel">
                                    {groupFigures}
                                    <div className="carousel-background">
                                        <div className="background-element">
                                        </div>
                                    </div>
                                </div>
                            </section>
                            </div>
                        </div>
                    </div>
                    <ScreenStepper
                        data={data}
                    />

                </div>
            </div>
        );
    }
}