/**
 * This handles all language select -actions
 */

export function changeLanguageFinnish(){
    return (dispatch, getState) => dispatch({
        type: 'LANGUAGE_FINNISH',
    });
}

export function changeLanguageSwedish() {
    return (dispatch, getState) => dispatch({
        type: 'LANGUAGE_SWEDISH',
    });
}

export function changeLanguageEnglish(){
    return (dispatch, getState) => dispatch({
        type: 'LANGUAGE_ENGLISH',
    });
}


