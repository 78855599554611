import React from 'react';
import {FormattedMessage} from 'react-intl';
import {getFacilitatorToken} from '../../../AxiosActions.js'

export default class LoginView extends React.Component {
    constructor(props) {
        super(props);
        this.getToken = this.getToken.bind(this);
        this.state = {
            username: "",
            password: "",
            displayLoginError: false
        };
        this.updateUsername = this.updateUsername.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
    }
    updateUsername(e) {
         this.setState({username: e.target.value, displayLoginError: false});
    }

    updatePassword(e) {
         this.setState({password: e.target.value, displayLoginError: false});
    }

    handleEnter(e) {
        if (e.key === 'Enter') {
            e.target.blur();
            this.getToken();
        }
    }

    getToken(){
        let sent_data = {
            username: this.state.username,
            password: this.state.password,
        };
        getFacilitatorToken(sent_data).then(res => {
            let token = res.data.token;
            let game_id = res.data.game_id;

            if (token !== null) {
                let d = new Date();
                d.setTime(d.getTime() + (3*60*60*1000));
                document.cookie = 'facilitator_TOKEN' + '=' + token + '; expires=' + d.toGMTString() + ';';
                this.props.logIn(game_id);
            }
        }).catch(error => {
            //wrong credentials
            if (error.response.status === 400) {
                 this.setState({displayLoginError: true});
            }
        });
    }
    render(){
        let loginError = this.state.displayLoginError ? (
            <div className="row">
                <div className="col-md">
                    <p className="app-paragraph" style={{paddingLeft:'27%', paddingRight:'27%', paddingTop:'1em', paddingBottom:'1em'}}><FormattedMessage id='app.login.wrongcredentials'/></p>
                </div>
            </div>) : null;

        return (
            <div className="app-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md">
                            <h1 className="app-title" style={{paddingLeft:'27%', paddingRight:'27%', paddingTop:'1em', paddingBottom:'1em'}}><FormattedMessage id='app.login.title'/></h1>
                        </div>
                    </div>
                    <form onSubmit={(e) => {e.preventDefault();}}>
                        <div className="row">
                            <div className="d-flex justify-content-center col-md">
                                <FormattedMessage id='app.login.username'>
                                    {(msg) => (<input className="game-select" type="text" onChange={this.updateUsername} style={{marginTop:'0.5em', width:'60%'}} placeholder={msg} autoCapitalize="off" autoComplete="off" spellCheck="false" autoCorrect="off" />)}
                                </FormattedMessage>
                            </div>
                        </div>
                        <div className="row">
                            <div className="d-flex justify-content-center col-md">
                                <FormattedMessage id='app.login.password'>
                                {(msg) => (<input className="game-select" onKeyPress={(e) => this.handleEnter(e)} type="password" id="login-password" onChange={this.updatePassword} style={{marginTop:'0.5em', width:'60%'}}
                                       placeholder={msg} />)}
                                </FormattedMessage>

                            </div>
                        </div>
                    </form>
                    {loginError}
                    <div className="row" style={{paddingTop: '5em'}}>
                        <div className="col-md">
                            <div>
                                <button className="orange-button" onClick={this.getToken}><FormattedMessage id='nextbutton'/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}