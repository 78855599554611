import {FINNISH_STATE} from 'static/locales/fi.js'
import {SWEDISH_STATE} from 'static/locales/se.js'
const initialState = FINNISH_STATE;
  

/**
 * This is a reducer handling all language changes
 */
  export default function languagesReducer(state = initialState, action) {
    switch (action.type) {
      case 'LANGUAGE_FINNISH':
        return FINNISH_STATE;
  
      case 'LANGUAGE_SWEDISH':
        return SWEDISH_STATE;  

      default:
        return state;
    }
  }
  