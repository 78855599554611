import React from 'react';
import select from "static/svg/select.svg";
import leaveGame from "static/svg/leave-game.svg";
import {FormattedMessage} from 'react-intl'


export default class AnswerScreen extends React.Component {
    constructor(props) {
        super(props);
        let {groups} = this.props.information.data;
        let shuffled = Object.keys(groups).map(key => {
            let group = groups[key];
            group['index_number'] =  key;
            return group;
        });
        this.state = {
            answer: null,
            shuffled: _.shuffle(shuffled)
        };
        this.sendVote = this.sendVote.bind(this);

    }

    sendVote(group){
        this.setState({answer: group});
        this.props.sendSocketMessage({
            command: 'openQuestion.playerVote',
            data: {
                vote: group,
            }
        });
    }

    render() {
        let optionsContent = Object.keys(this.state.shuffled).map((index) => {
            let group = this.state.shuffled[index];
            let answer = group.answer;
            return (
                <div className="row">
                    <div className="d-flex justify-content-center col-md" style={{paddingTop:'3rem'}}>
                        <div className="app-option-container" onClick={() => this.sendVote(group.index_number)}>
                            <p className="app-option-text"> {answer} </p>
                        </div>
                    </div>
                </div>
                )
            });
        return (
            <div className="app-container">
            <div className="container-fluid scrollable-container player-container">
                <span className="exit-button" onClick={() => this.props.exitGame(this.props.module) }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>
                <div className="row">
                    <div className="d-flex justify-content-center col-md">
                        <img className="hint-icon" style={{marginTop:'5rem'}} src={select}/>
                    </div>
                </div>

                <div className="row">
                    <div className="title-container col-md" style={{paddingTop:'2rem', marginBottom:0,}}>
                        <h1 className="app-title">
                            <FormattedMessage id='player.openquestion.answerscreen.voteinstruction' />
                        </h1>
                    </div>
                </div>
                {optionsContent}
                <div className="padding" style={{paddingBottom:'20%'}}>
                </div>
            </div>
            <div className="fade-element"></div>
            </div>
        );
    }
}